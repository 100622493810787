/* @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;300;400;500;600;700&display=swap');


@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    /* font-family: 'Montserrat', sans-serif; */
    font-family: 'Outfit', sans-serif;
    /* font-family: 'Inter', sans-serif; */
}

h1, h2, h3, h4, h5 {
    font-family: 'Outfit', sans-serif;
}

/* width */
::-webkit-scrollbar {
    width: 5px;
}
  
/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}
  
/* Handle */
::-webkit-scrollbar-thumb {
    background: #d0d5dd;
    border-radius: 5px;
}
  
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #eaecf0;
}
